.container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  margin: 2em auto 0 auto;
}

.container span {
  font-size: 1em;
  text-align: center;
  text-transform: uppercase;
  margin: 0 0.5em;
}

.container button {
  font-size: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 5px;
  margin: 0 0.25em;
  border-radius: 5px;
  color: white;
  background: var(--accent);
  cursor: pointer;
  border: none;
  transition: all 0.3s ease-in-out;
}
.container button:hover {
  transform: scale(1.05);
  box-shadow: 0px 0px 0px 2px var(--shadow);
}
.container button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
  transform: none;
}
.container button span {
  width: 26px;
}
.container button span img {
  width: 100%;
}
