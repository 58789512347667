.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background-color: var(--electric);
}

.imgContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 350px;
}
.imgContainer img {
  width: 100%;
}

.title {
  font-size: 4rem;
  font-weight: 900;
  color: white;
  margin: 0 0 1.5rem 0;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.playButton {
  border-radius: 25px;
  color: white;
  font-size: 1.5em;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 2px;
  background: var(--accent);
  padding: 10px 20px;
  border: 0;
  filter: drop-shadow(3px 3px 3px rgba(76, 76, 76, 0.5));
  cursor: pointer;
}
