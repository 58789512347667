.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100vh;
  padding: 2em;
  background-color: var(--bug);
}

.backButtonContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  margin: 0.5em;
  position: absolute;
  top: 3vw;
  left: 3vw;
}

.backButtonContainer button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  color: white;
  background: var(--accent);
  cursor: pointer;
  border: none;
  margin: 0 0.25rem;
  font-size: 14px;
  text-transform: uppercase;
}
