.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 80px;
  width: 100%;
  padding: 3em 2em;
}

.logoContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 200px;
}
.logoContainer img {
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;
}
.navbar ul {
  display: flex;
  align-items: center;
  list-style: none;
}

.navBarButton {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  color: white;
  background: var(--accent);
  cursor: pointer;
  border: none;
  transition: all 0.3s ease-in-out;
  margin: 0 0.25rem;
  font-size: 14px;
  text-transform: uppercase;
}
.navBarButton:hover {
  transform: scale(1.05);
  box-shadow: 0px 0px 0px 2px var(--shadow);
}
