.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 3em auto 2em auto;
}

.imageContainer {
  display: flex;
  align-self: flex-start;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 300px;
  min-height: 300px;
  margin: 2em auto;
  cursor: pointer;
}

.imageContainer img {
  width: 100%;
}

.imageContainer button {
  padding: 0.5em 1em;
  margin: 1em;
  background-color: var(--bug);
  border: none;
  border-radius: 7px;
  color: white;
  filter: drop-shadow(1px 1px 3px rgba(38, 77, 56, 0.4));
  font-size: 0.85em;
  text-transform: uppercase;
  letter-spacing: 1px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}
.imageContainer button:hover {
  transform: scale(1.05);
  filter: drop-shadow(1px 1px 4px rgba(38, 77, 56, 0.4));
}

.formSection {
  display: flex;
  max-width: 600px;
  width: 100%;
  margin: 20px auto 0 -22vw;
}

.form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  color: white;
  font-size: 1.1em;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.nameContainer {
  display: flex;
  align-items: center;
  width: 76%;
  margin: 0.5em 1em 0.5em 0;
}

.form input[type="text"] {
  width: 50%;
  padding: 0.5em 1em;
  margin: 0 0.75em;
  border: none;
  border-radius: 3px;
  font-size: 0.85em;
}

.typesSelectorsContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.typeSelectorContainer {
  display: flex;
  width: 75%;
  margin: 0.5em 1em 0.5em 0;
}

.form select {
  width: 50%;
  padding: 0.5em 1em;
  margin: 0 0.75em;
  border: none;
  border-radius: 3px;
  font-size: 0.85em;
}

.form input[type="range"] {
  width: 60%;
  margin: 0.25em 0.75em;
  font-size: 0.85em;
}

.form label {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  margin: 0.25em;
}

.errorContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 500px;
  margin: 0.5em;
}

.error {
  color: red;
  font-size: 0.85em;
  font-weight: 400;
  text-transform: none;
  letter-spacing: 0;
}

.submitButton {
  align-self: flex-start;
  padding: 0.5em 1em;
  margin: 3.5em 1em 1em 1em;
  background-color: var(--accent);
  border: none;
  border-radius: 7px;
  color: white;
  filter: drop-shadow(1px 1px 3px rgba(38, 77, 56, 0.4));
  font-size: 0.85em;
  text-transform: uppercase;
  letter-spacing: 1px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}
.submitButton:disabled {
  background-color: rgba(244, 167, 37, 0.5);
  color: rgba(255, 255, 255, 0.5);
  cursor: not-allowed;
}

.imageModal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.imageThumbnails {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background-color: white;
  padding: 20px;
  border-radius: 5px;
}

.thumbnail {
  width: 80px;
  height: 80px;
  cursor: pointer;
  border: 1px solid #ccc;
  border-radius: 5px;
  object-fit: cover;
}

.thumbnail:hover {
  border-color: #999;
}

.closeButton {
  position: absolute;
  top: 3.5vw;
  right: 3.5vw;
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
  color: white;
}

.closeButton:focus {
  outline: none;
}

.selectedImage {
  width: 100%;
  max-width: 200px;
}
