.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  margin: 0.75rem 0;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 1.5em auto 0 auto;
}

.title {
  font-size: 0.95rem;
  font-weight: 600;
  text-transform: uppercase;
}

.radioContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.radioContainer label {
  font-size: 0.75rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-right: 0.5rem;
}
.radioContainer input {
  width: 1.25rem;
  height: 1.25rem;
  margin: 0 0.25rem;
}

.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin: 0.25rem 0;
}

.main button {
  background-color: var(--accent);
  border: none;
  cursor: pointer;
  padding: 0.5rem 1rem;
  border-radius: 2px;
  color: white;
  font-size: 0.75rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin: 0.5rem 0;
  filter: drop-shadow(1px 1px 1px rgb(121, 121, 121, 0.5));
  transition: all 0.2s ease-in-out;
}
.main button:disabled {
  background-color: rgba(204, 205, 207, 0.5);
  cursor: not-allowed;
}

.checksContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 0.25rem 0;
}
.checksContainer label {
  display: flex;
  align-items: center;
  font-size: 0.75rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin: 0.25rem;
  padding: 0.5rem;
  width: 100%;
  /* background-color: rgb(204, 205, 207);
  border-radius: 10px;
  filter: drop-shadow(1px 1px 1px rgb(121, 121, 121, 0.5)); */
}
.checksContainer input {
  width: 1.1rem;
  height: 1.1rem;
  margin: 0 0.25rem;
}
