.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  min-height: 75vh;
}
.containerEmpty {
  justify-content: center;
}
.containerEmpty p {
  font-size: 1.25rem;
  font-weight: 500;
  margin: 2rem;
}

.imgContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 318px;
  height: 253px;
}
.imgContainer img {
  width: 100%;
  height: 100%;
}

.main {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
