:root {
  --bug: rgba(157, 193, 48, 1);
  --dark: rgba(95, 96, 109, 1);
  --dragon: rgba(7, 115, 199, 1);
  --electric: rgba(244, 206, 70, 1);
  --fairy: rgba(239, 151, 230, 1);
  --fight: rgba(217, 66, 86, 1);
  --fire: rgba(248, 165, 79, 1);
  --flying: rgba(155, 180, 232, 1);
  --ghost: rgba(105, 112, 197, 1);
  --grass: rgba(93, 190, 98, 1);
  --ground: rgba(215, 133, 85, 1);
  --ice: rgba(126, 212, 201, 1);
  --normal: rgba(154, 157, 161, 1);
  --poison: rgba(181, 99, 206, 1);
  --psychic: rgba(247, 100, 141, 1);
  --rock: rgba(206, 193, 140, 1);
  --steel: rgba(85, 150, 164, 1);
  --water: rgba(85, 158, 223, 1);
  --shadow: rgba(132, 136, 132, 0.3);
  --accent: rgba(244, 167, 37, 1);
  --textDark: rgba(33, 33, 33, 1);
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--textDark);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
